import React, { useState, ReactNode } from 'react'
import { Button, Slider, Switch, TextField, Box, FormGroup, FormControlLabel } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from '@mui/icons-material/Settings';

import CometRotatingInsideElementOnPerimeter from "./CometRotatingInsideElementOnPerimeter"
import ModalCardMuiSsr from '../ModalCardMuiSsr';

type Props = {
    children?: ReactNode;
}


const sliderMarks = [

    {
        value: 50,
        label: '50px',
    },
    {
        value: 250,
        label: '250px',
    },
    {
        value: 500,
        label: '500px',
    },
];

const CometWithForm = (props: Props) => {
    const { children } = props
    // const refWrap =useRef(null)
    const [options, setOptions] = useState({ x: true, y: true, z: true });
    const [sizePx, setSizePx] = useState(250);
    const toggleModal = () => {
        setOpen((prev) => !prev);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleWidthChange = (event: any, newValue: number | number[]) => {
        setSizePx(newValue as number);
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptions({ ...options, [event.target.name]: event.target.checked });
    };


    return (
        <Box sx={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: open ? "primary.main" : "transparent",
        }}
        // ref={refWrap}
        >
            <IconButton
                onClick={toggleModal}
                aria-label="expand"
                size="medium"
                sx={{ "& svg": { fontSize: "medium" } }}
            >
                <SettingsIcon />
            </IconButton>
            <ModalCardMuiSsr handleOpen={handleOpen} handleClose={handleClose} toggleModal={toggleModal} open={open}>
                <br />
                <FormGroup sx={{ flexDirection: "row" }} >
                    <FormControlLabel
                        control={
                            <Switch
                                // defaultChecked
                                color="primary"
                                checked={options.x}
                                onChange={handleOptionChange}
                                name="x"
                                inputProps={{ "aria-label": "x axis" }}
                            />
                        }
                        label="x"
                        labelPlacement="top"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                color="error"
                                checked={options.y}
                                onChange={handleOptionChange}
                                name="y"
                                inputProps={{ "aria-label": "y axis" }}
                            />
                        }
                        label="y"
                        labelPlacement="top"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={options.z}
                                onChange={handleOptionChange}
                                name="z"
                                inputProps={{ "aria-label": "z axis" }}
                            />
                        }
                        label="z"
                        labelPlacement="top"
                    />
                </FormGroup>

                <br />
                <Box sx={{ p: { xs: 0.5, sm: 2 }, border: '1px dashed grey', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <Slider
                        value={sizePx}
                        min={50}
                        max={500}
                        marks={sliderMarks}
                        onChange={handleWidthChange}
                        sx={{ maxWidth: "65%" }}
                    />
                    <TextField
                        label="Width"
                        value={sizePx}
                        onChange={(e) => setSizePx(parseInt(e.target.value))}
                        sx={{ maxWidth: "25%" }}
                    />
                </Box>
                <br />

            </ModalCardMuiSsr>
            <CometRotatingInsideElementOnPerimeter
                options={options}
                wrapstyle={{ width: `${sizePx}px`, height: `${sizePx}px`, }}
            >
                {children}
            </CometRotatingInsideElementOnPerimeter>
        </Box >
    );
}

export default CometWithForm