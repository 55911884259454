import React, { ReactElement, ReactNode, useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from '@mui/icons-material/Settings';
import Modal from '@mui/material/Modal';

interface ModalCardMuiSsrProps {
    children?: ReactNode;
    wrapstyle?: Record<string, string>;
    handleOpen: any;
    handleClose: any;
    toggleModal: any;
    open: boolean;
}

export default function ModalCardMuiSsr(props: ModalCardMuiSsrProps): ReactElement {
    const { children, wrapstyle = {}, handleOpen, handleClose, toggleModal, open } = props;

    const rootRef = useRef<HTMLDivElement>(null);


    return (
        <div
            style={{
                position: "relative",
                width: "fit-content",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                right: "0vh",
                top: 0,
                zIndex: 99,
                ...wrapstyle,
            }}
            ref={rootRef}
        >

            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={open}
                onClose={handleClose}
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                sx={{
                    display: 'flex',
                    p: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                container={() => rootRef.current}
            >
                <Card
                    sx={{
                        border: "1px solid rgba(211,211,211,0.6)",
                        top: '0',
                        position: 'fixed',
                        right: { xs: "unset", sm: "3rem" },
                        margin: { xs: "10px" }
                    }}
                >
                    <CardHeader
                        action={
                            <IconButton onClick={toggleModal} aria-label="expand" size="small">
                                <SettingsIcon />
                            </IconButton>
                        }
                    ></CardHeader>
                    <CardContent sx={{ borderStyle: 'ridge' }} >
                        <Container sx={{}}>
                            {children}
                        </Container>
                    </CardContent>
                </Card>
            </Modal>
        </div>
    );
}

// ModalCardMuiSsr.defaultProps = {
//     wrapstyle: {
//     },
// };
