import React, { useRef, ReactNode, ReactElement, useState, useLayoutEffect, useEffect, useContext, CSSProperties } from 'react'
import { NotInViewContext } from '../AnimateScrollIntoViewport';
import * as styles from "./cometRotatingInsideElementOnPerimeter.module.css"
import AnimationButton from '../AnimationButton';
import AnimationCycleTimeSlider from '../AnimationCycleTimeSlider';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';
interface CometProps {
    children?: ReactNode;
    options?: Record<string, boolean>;
    wrapstyle?: Record<string, string>;
    // style?: {
    //     backgroundColor: string | void;
    //     color: string | void;
    //     fontSize?: number;
    //   }
}

interface CometDirProps {
    children?: ReactNode;
    direction: "x" | "y" | "z"
}

function CometDimPart({ direction, children }: CometDirProps) {
    return (
        <div className={`${styles?.wrapComet} ${styles?.[`dir_${direction}`]}`} >
            <div className={styles?.comet}>
                {children}
                <div className={styles?.circleComet}>
                    <p>Lorem Ipsum</p>
                </div>
            </div>
        </div>)
}

const CometRotatingInsideElementOnPerimeter = ({
    children, options = { x: true, y: true, z: true },
    wrapstyle = { height: "250px", width: "250px" }
}: CometProps): ReactElement => {

    const { x, y, z } = options
    const ref = useRef(null)
    const [duration, setDuration] = useState(3);
    const [animation, setAnimation] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const { notInView, isCountedTwice } = useContext(NotInViewContext);
    // console.log("CometRotatingInsideElementOnPerimeter CometWithForm notInView", notInView)

    const handleToggleAnimationPlay: React.MouseEventHandler<HTMLButtonElement> = (e: React.MouseEvent<HTMLButtonElement>) => {
        // setAnimation(!animation)
        !isClicked && setIsClicked(true)
        setAnimation((prev) => !prev)
    }

    // console.log("CometRotatingInsideElementOnPerimeter CometWithForm notInView, isCountedTwice, animation", notInView, isCountedTwice, animation)
    // useLayoutEffect(() => {
    //     console.log("useEff CometRotatingInsideElementOnPerimeter CometWithForm notInView", notInView)

    //     // if (notInView && animation) {
    //     if (notInView) {
    //         // setAnimation(false)
    //     } 
    // }, [notInView]) //

    const getClassToAnimate = () => {
        switch (true) {
            case isClicked && animation:
                return `${styles.animate}`
            case notInView:
                if (animation) setAnimation(false)
                return ""
            case !isClicked && !notInView && isCountedTwice:
                if (!animation) setAnimation(true)
                return `${styles.animate}`
            default:
                return ""
        }
    }

    const classToAnimate = getClassToAnimate()
    // console.log("CometRotatingInsideElementOnPerimeter classToAnimate", classToAnimate)
    // console.log("CometRotatingInsideElementOnPerimeter styles.animate", styles.animate)
    return (<>
        <div
            ref={ref}
            // className={isClicked && animation ? `${styles.animate}` : (notInView ? "" : animation ? `${styles.animate}` : "")}
            // className={
            //     isClicked && animation
            //         ? `${styles.animate}`
            //         : notInView && isCountedTwice
            //             ? ""
            //             : !isClicked && !notInView && isCountedTwice
            //                 ? `${styles.animate}`
            //                 : ""
            // }
            className={`${classToAnimate}`}
            // className={isClicked && animation ? `${styles.animate}` : notInView ? "" : animation ? `${styles.animate}` : ""}
            // className={`wrapxyz ${classToAnimate}`}
            style={{
                position: "relative",
                marginLeft: "auto",
                marginRight: "auto",
                ...wrapstyle,
                "--duration": `${duration}s`
            } as CSSProperties}>
            {
                x && <CometDimPart direction='x'>
                    {children}
                </CometDimPart>
            }
            {
                y && <CometDimPart direction='y'>
                    {children}
                </CometDimPart>
            }
            {
                z && <CometDimPart direction='z'>
                    {children}
                </CometDimPart>
            }
        </div >
        <AnimationButton handleClickStartStopAnimation={handleToggleAnimationPlay} >
            {animation ? <StopCircleIcon /> : <PlayCircleOutlineIcon />}
        </AnimationButton>
        <AnimationCycleTimeSlider
            duration={duration}
            setDuration={setDuration}
            title="Zapni/vypni"
        />
    </>
    )
}

export default CometRotatingInsideElementOnPerimeter



