// extracted by mini-css-extract-plugin
export var animate = "cometRotatingInsideElementOnPerimeter-module--animate--1020d";
export var circleComet = "cometRotatingInsideElementOnPerimeter-module--circleComet--fa879";
export var comet = "cometRotatingInsideElementOnPerimeter-module--comet--48229";
export var dir_x = "cometRotatingInsideElementOnPerimeter-module--dir_x--5b77e";
export var dir_y = "cometRotatingInsideElementOnPerimeter-module--dir_y--dcff3";
export var dir_z = "cometRotatingInsideElementOnPerimeter-module--dir_z--f8483";
export var rotateXComet = "cometRotatingInsideElementOnPerimeter-module--rotateXComet--45eff";
export var rotateYComet = "cometRotatingInsideElementOnPerimeter-module--rotateYComet--3844b";
export var rotateZComet = "cometRotatingInsideElementOnPerimeter-module--rotateZComet--6c31d";
export var wrapComet = "cometRotatingInsideElementOnPerimeter-module--wrapComet--01201";